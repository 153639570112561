/* ......NEW DESIGN CHGS........ */
body{
    background-color: #000336;
}
.a *,.s *,.r *,.i-right *,.i-social *{
    border-radius: 0px !important;
    box-shadow: none;
}
.c *{
    border-radius: 0px !important;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.811);
    box-shadow: none;
}
.things,.c-form,.progress,.rightsmall,.c-s-links-min{
    border: 1px dashed #76B1DD;
}
.rightsmall,.fa-chevron-right{
    background-color: transparent;
    color: rgba(255, 255, 255, 0.811);
}
.heading{
    text-align: center;
    color: #59B256 !important;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Poppins', sans-serif !important;
    height: auto !important;
    max-height: auto !important;
    padding-top: 20px !important;
    z-index: 100;
    font-size: 28px !important;
    backdrop-filter: blur(10px);
}
.c-s-links-title h2{
    font-size: 28px !important;
}
@media (max-width:768px){
    .heading,.c-s-links-title h2{
        font-size: 20px !important;
    }
}
.skill,.resume-title{
    color: #76B1DD;
}
.r h4{
    color: #76b0ddd3 ;
}
.r p{
    color: rgba(255, 255, 255, 0.811);
}
.menu{
    color: rgba(255, 255, 255, 0.737);;
}
.a-container,.s-container,.r-container,.c-container{
    padding: 0px 15px 25px 15px !important;
    margin-top: -25px !important;
}
.upper{
    margin-top: 0px !important;
}
/* Scrollbar */
*::-webkit-scrollbar {
    width: 6px;
  }
  
  *::-webkit-scrollbar-track {
    background: black;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #76B1DD;
    border: 1px solid black;
  }


  html.lenis{height:auto}.lenis.lenis-smooth{scroll-behavior:auto}.lenis.lenis-smooth [data-lenis-prevent]{overscroll-behavior:contain}.lenis.lenis-stopped{overflow:hidden}.lenis.lenis-scrolling iframe{pointer-events:none}
