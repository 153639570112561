.i-left {
    flex: 1;
    border-right: 1px dashed #76B1DD;
    background-color: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 270px;
    transition: all ease-in-out 0.5s;
    z-index: 300;
    transition: all 0.5s;
    /* background: #000e22; */
    overflow-y: auto;
    height: 100vh;
    display: flex;
}

.i-left-wrapper {
    margin: 15px auto;
    text-align: center;
    align-items: center;
}


.i-left-img {
    width: 40%;
    height: 40%;
    border: 8px solid rgb(44, 47, 63);
    border-radius: 50%;
}

.i-left-name {
    padding: 10px 0px;
    color: #59b256;
}

.i-social {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
}

.c-logo {
    padding: 12px;
    font-size: 15px;
    text-decoration: none;
}
.c-logo:hover{
    text-decoration: none;
}
.icon-background{
    content: "";
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    background-color: rgb(33,36,49);
}
.icon-background:hover{
    transition: 0.2s ease-in-out;
    background-color: #149ddd;
}
.social-icon{
    text-decoration: none;
    color: white;
    font-size: 21px;
    display: flex;
    padding-left: 7px;
    align-items: center;
    justify-content: center;
}
.social-icon:hover{
    text-decoration: none;
}

.i-left-mid {
    margin: 0px;
    padding: 10px;

}

.i-container {
    /* margin: 0px; */
    padding-top: 25px;
    padding-bottom: 0px;
    padding-right: 25px;
    width: 100%;
}
.menu {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 20px 10px 10px 20px;
    font-size: medium;
    text-decoration: none;
    color: rgb(111, 113, 128);
    cursor: pointer;
}

.menu:visited {
    text-decoration: none;
}

.menu:hover {
    text-decoration: none;
}
.menu:hover .icon {
    color: rgb(20, 157, 221);
}
.menu:hover .navBut {
    color: rgb(249, 249, 249);
}

.menu:active {
    text-decoration: none;
    color: rgb(20, 157, 221);
}

.icon {
    font-size: 25px;
}

.navBut {
    padding-left: 15px;
}

/*---footer------*/
#footer {
    padding: 15px;
    color: #f4f6fd;
    font-size: 14px;
    margin-top: 40px;
    width: 100%;
    z-index: 9999;
    background: #040b14;
}


/*---------media queries------------*/
@media (max-width:1200px){
    .i-left{
        display: none;
    }
}