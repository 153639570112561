.c{
    padding-left: 300px;
    padding-right: 10px;
    /* position: absolute; */
    display: flex;
    align-items: center;
    justify-content: center; 
    overflow-wrap: break-word;
}
.c-container{
    padding-top: 25px;
    padding-bottom: 0px;
    padding-right: 50px;
    width: 100%;
}
.contact-title{
    padding-bottom :20px;
}
.contact-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #173b6c;
    font-family: "Raleway", sans-serif;
}
.contact-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    background: #149ddd;
    bottom: 0;
    left: 0;
}

.c-row{
    display: flex;
    flex-wrap: wrap;
    padding-bottom:30px;
}
.c-left-side{
    will-change: transform;
    width: 42%;
    padding: 10px;
    display: flex;
    align-items: stretch;
}
.c-right-side{
    will-change: transform;
    padding: 10px;
    width: 58%;
    display: flex;
    align-items: stretch;
}
.things{
    border-radius: 20px;
    width: 100%;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.15);
}
.c-form{
    width: 100%;
    border-radius: 20px;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.15);
}
.c-s-links{
    will-change: transform;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: stretch;
}
.c-s-links-min{
    border-radius: 20px;
    width: 100%;
    padding: 30px 30px 60px 30px;
    background: #fff;
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.15);
    /* display: flex; */
}
.c-s-links-title{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #173b6c;
    font-family: "Raleway", sans-serif;
    display: flex;
    justify-content: center;
}
.c-s-links-icons{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.btn {
    background: #e3edf7;
    padding: 10px;
    border-radius: 10px;
    /* box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.15),
            -6px -6px 10px -1px rgba(255,255,255,0.7);
    border: 1px solid rgba(0,0,0,0); */
    cursor: pointer;
    transition: transform 0.5s;
  }
  
  .btn:hover {
    /* box-shadow: inset 4px 4px 6px -1px rgba(0,0,0,0.2),
            inset -4px -4px 6px -1px rgba(255,255,255,0.7),
            -0.5px -0.5px 0px rgba(255,255,255,1),
            0.5px 0.5px 0px rgba(0,0,0,0.15),
            0px 12px 10px -10px rgba(0,0,0,0.05);
    border: 1px solid rgba(0,0,0,0.1); */
    transform: translateY(0.5em);
  }
  
  .btn svg {
    transition: transform 0.5s;
  }
  
  .btn:hover svg {
    transform: scale(0.9);
    fill: #59B256;
  }
  
  
.cont-icons{
    font-size: 19px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: #149ddd;
    background: #dff3fc;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dff3fc;
    float: left;
}
.icon-title{
    padding-left:60px;
    margin-bottom: 5px;
    color: #050d18;
    font-weight: 600;
    font-size: 22px;
}
.icon-detail{
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 15px;
    color: #173b6c;
}
.address{
    overflow-wrap: anywhere;
}
.email{
    overflow-wrap: break-word;
}
.phone{
    overflow-wrap: break-word;
}
.address:hover .cont-icons {
    background-color: #149DDD;
    color: white;
    transition: 0.3s ease-in-out;
}
.email:hover .cont-icons {
    background-color: #149DDD;
    color: white;
    transition: 0.3s ease-in-out;
}
.phone:hover .cont-icons {
    background-color: #149DDD;
    color: white;
    transition: 0.3s ease-in-out;
}

.ftr{
    display: none;
}
@media (max-width:1200px){
    .c{
        padding-left: 0px;
        padding-right: 0px;
    }
    .c-container{
        padding: 25px 15px 0px 15px;
    }
    .c-left-side{
        width: 100%;
    }
    .c-right-side{
        width: 100%;

    }
    .things{
        padding: 25px 20px;
    }
    .c-form{
        padding: 30px 25px ;
    }
    .icon-title {
        font-size: 19px;
    }
    .icon-detail {
        font-size: 14px;
    }
    .c-s-links-icons{
        justify-content: space-between;
    }
    .c-s-links-icons a .btn{
        padding: 5px;
        padding-bottom: 3px;
    }
    .c-s-links-icons a .btn svg{
        width:24px;
        height:24px;
    }
    .ftr{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}