
.a{
    width: 100%;
    height: 100%;
    padding-left: 300px;
    padding-right: 10px;
    /* position: absolute; */
    display: flex;
    align-items: center;
    justify-content: center; 
    overflow-wrap: break-word;
}
.a-container{
       padding-top: 25px;
    padding-bottom: 0px;
    padding-right: 50px;
    width: 100%;
} 
.heading{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #173b6c;
    font-family: "Raleway", sans-serif;
}
.heading::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    background: #149ddd;
    bottom: 0;
    left: 0;
}
.desc{
    font-size: 22px;
}
ul{
    list-style: none;
    padding-left: 0px;
}
.details{
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: row; */
}
.left-one{
    will-change: transform;
    width : 30%;
    padding-right: 15px;
    padding-left: 15px;
    height: fit-content;
}
.right-one{
    will-change: transform;
    width : 70%;
    margin-top: 20px;
    padding-right: 15px;
   padding-left: 15px;
}
.rightsmall{
    border-radius: 20px;
    margin-top: 0px;
    padding: 30px 25px;
    background: #fff;
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.15);
}
.rightsmall-head{
    font-size: 20px;
    margin-top: 0px;
}
.about-small-desc{
    font-size: 18px;
}
.about-img{
    /* padding-right: 10px; */
    border-radius: 20px;
    max-width: 100%;
    height: min-content;
    margin-top: 20px;
    padding: 0px ;
    background: #fff;
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.15);
}
.a-row{
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
}
.a-row-left{
    overflow-wrap: anywhere;
}
.a-row-right{
    padding-left: 8px;
    overflow-wrap: anywhere;
}
li{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.strng{
    margin-right: 10px;
    margin-left: 10px;
    overflow-wrap: normal;
}

.bottom{
    font-size: 17px;
}
@media (max-width:768px){
    .details{
        flex-direction: column;
    }
    .left-one{
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }
    .right-one{
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }
}
@media (max-width:1200px){
    .a{
        padding-left: 0px;
        padding-right: 0px;
    }
    .a-container{
        padding: 25px 15px 0px 15px;
    }
    .a-row{
        font-size: 15px;
    }
    .a-row-right{
        padding-left: 0px;
    }
    
    
    .about-small-desc {
        font-size: 16px;
        /* color: #537173; */
    }
    .strng {
        font-weight: 600;
    }
    .bottom {
        font-size: 15px;
        letter-spacing: 1.3;
        line-height: 24px;
    }
}
