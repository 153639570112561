@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body,html{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.header{
    overflow: hidden;
    height: 100svh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: transparent;
    z-index: 500;
    position: fixed;
    top: 0;
    /* pointer-events: none; */
}
.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 400;
    display: flex;
    /* pointer-events: none; */
}
.bar{
    width: 12.5vw;
    height: 100svh;
    background: black;
    background: #00011b;
    
}
.myText{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15vw;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    /* line-height: 10.5vw; */
    height: 12vw;
}
.char{
    cursor:default;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(14vw);
    transition: transform 0.5s;
    color: #fff;
    font-family: 'Bebas Neue', sans-serif;

}
.dot{
    color: #59B256;
}