.s{
    /* color: white; */
    padding-left: 300px;
    padding-right: 10px;
    /* position: absolute; */
    display: flex;
    align-items: center;
    justify-content: center; 
    overflow-wrap: break-word;
}
.s-container{
    padding-top: 25px;
    padding-bottom: 0px;
    padding-right: 50px;
    width: 100%;
}
.section-title{
    padding-bottom :20px;
}
.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #173b6c;
    font-family: "Raleway", sans-serif;
}
.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    background: #149ddd;
    bottom: 0;
    left: 0;
}
p{
    margin: 0 0 10px;
}
.row{
    font-size: 20px;
    margin-right: -15px;
    margin-left: -15px;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}
.progress{
    will-change: transform;
    height: 80px;
    display: block;
    background: none;
    padding: 10px;
    border-radius: 25px;
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.15);
}
.skill {
    padding: 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #050d18;
}
.val {
    float: right;
    font-style: normal;
}
.progress-bar-wrap{
 background-color: #dce8f8;
 height: 15px;
 border-radius: 25px;
}
.progress-bar{
    transition: 0.9s ease-in-out;
    background-image: linear-gradient(to right, #150676, #151b82, #172b8e, #1b3a99, #2148a4, #314faa, #3d56b1, #495db7, #5b5fbb, #6b60be, #7b61c1, #8a62c3);
    height: 15px;
    display: flex;
    border-radius: 25px;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}
@media (max-width:1200px){
    .s{
        padding-left: 0px;
        padding-right: 0px;
    }
    .s-container{
        padding: 25px 15px 0px 15px;
    }
    .skill{
        font-weight: 500;
        font-size: 15px;
    }
    .progress{
        height: 60px;
        border-radius: 15px;
    }
    .progress-bar-wrap{
        height: 10px;
    }
    .progress-bar{
        height: 10px;
    }

}