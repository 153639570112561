.form-group label{
   font-size: 18px; 
   font-weight: 500;
}
.c-form-submit{
    display: flex;
    align-items: center;
    justify-content: center;
}
.c-form-submit button {
    font-family: inherit;
    font-size: 20px;
    background: royalblue;
    color: white;
    padding: 0.7em 1em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
  }
  
  .c-form-submit button span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }
  
  .c-form-submit button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }
  
  .c-form-submit button:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }
  
  .c-form-submit button:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
  }
  
  .c-form-submit button:hover span {
    transform: translateX(5em);
  }
  
  .c-form-submit button:active {
    transform: scale(0.95);
  }
  
  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }
  
    to {
      transform: translateY(-0.1em);
    }
  }
  